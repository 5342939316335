* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  cursor:none;
}

body {
  background: #141c32;
}

a {
  color: unset;
  text-decoration: none;
}

.site {
  overflow-y: hidden;
}

.modal {
  background-color: #464f67;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 574px;
  height: 320px;
  border-radius: 34px;
}

.modalbutton {
  width: 110px;
  height: 32px;
  background: rgba(196, 196, 196, 0.3);
  border-radius: 10px;
  color: #ffffff;
}
.modalbutton2 {
  width: 110px;
  height: 32px;
  background: #010920;
  border-radius: 10px;
  color: #ffffff;
  border: 1px solid #00d1ff;
}

.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  z-index: 999;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.cursor-dot {
  width: 8px;
  height: 8px;
  background-color: #dc5656;
}

.cursor-dot-outline {
  width: 40px;
  height: 40px;
  background-color: rgba(229, 128, 128, 0.5);
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (min-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}
