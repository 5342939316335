.time-live {
  margin: auto;
  font-family: "HP Simplified";
  font-style: italic;
  font-weight: 400;
  font-size: 36px;
  color: #ffffff;
  text-align: center;
  margin-top: 2%;
}

.timerText {
  margin: auto;
  width: 385px;
  height: 80px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  margin-top: 6%;
}

.timerpic {
  display: flex;
  width: 418px;
  height: 316px;
  z-index: -1;
  margin: auto;
  margin-top: 5%;
}

.center {
  width: fit-content;
  margin: auto;
}

@media screen and (min-width: 992px) {
  .timerpic {
    width: 856px;
    /*height: 646px; */
    margin-top: auto; 
    
  }
}

@media screen and (min-width: 992px) {          /*added property*/
  .timerText {
    width: 685px;
    font-size: 40px;
  }
}
