.titleclass {
    display: flex;
    justify-content: space-between;
}
.cards{
    width: 300px !important;
    border-radius: 10px !important;
    border: 3px solid hsl(191, 100%, 50%) !important;
    background: #010920 !important;
}
.title{
    color: white;
    font-size: 14px;
}
.expand{
    color: white;
    text-align: center !important;
}
.line{
    height: auto;
}
.css-1rwjz6-MuiCardActions-root {
    padding-right: 8px;
}
.para{
    color: white;
}

.middlebutton {
    color: white !important;
    font-size: 12px !important;
    border: 2px solid #00D1FF !important;
    border-radius: 8px !important;
    height: 38px;
    background: transparent;
    width: 141px;
    font-weight: 500;
}
.middlebuttondiv{
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    background: transparent !important;
}
.middlebutton2 {
    background-color: #16DE66 !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    border-radius: 8px !important;
    height: 38px;
    width: 100px;
}
.datas{
    padding:8px;
}