.instruction_body {
  text-align: -webkit-center;
  margin-top: 20px;
  overflow-y: auto;
  max-height: 85vh;
}

/*Remove comment from next line to hide scrollbar*/
/*-ms-overflow-style: none;  
  scrollbar-width: none;
}

.instruction_body::-webkit-scrollbar {
  display: none;
}*/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.heading1 {
  width: 318px;
  height: 35px;
  margin: 25px;
  margin-left: 32px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}

.heading2 {
  width: 203px;
  height: 44px;
  margin: 14px;
  margin-top: 40px;
  margin-bottom: 2px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.instructionstext {
  margin: 40px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  text-align: left;
}

.instructionstext li {
  padding: 10px;
}

.proceed {
  width: 220px;
  height: 50px;
  border: 1px solid #d1d1d1;
  background: #00d1ff;
  border-radius: 31px;
  margin: 10px;
  margin-bottom: 35px;
  padding: 5px;
  margin-left: 35px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.progress-container {
  width: 100%;
  height: 5px;
  background: #4d4e50;
  position: fixed;
  bottom: 8px;
  left: 0px;
  text-align: left;
}

/* The progress bar (scroll indicator) */
.progress-bar {
  height: 5px;
  background: #ffffff;
  width: 0%;
}

.progress {
  background-color: #000000;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 25px;
}
