.searchInputs {
  margin-top: 20px;
  display: flex;
  height: 54px;
  border: 2px solid #ffffff;
  border-right: none;
  border-radius: 10px;
  background-color: #010920;
}
.searchdiv {
  display: flex;
  justify-content: center;
  margin-bottom: 34px;
}
.search input {
  background-color: #010920;
  font-size: 18px;
  padding: 15px;
  width: 350px;
  margin-right: 3px;
  margin-right: 19px !important;
  margin-left: 25px !important;
  height: 30px;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  color: #c4c4c4;
  margin: auto;
}
::-webkit-input-placeholder {
  background-color: transparent !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  text-align: left !important;
}
.searchicon {
  color: white;
  padding: 0px !important;
}
.icondiv {
  background-color: #00d1ff;
  height: 50px;
  border-right: 2px solid #ffffff;
  border-radius: 10px;
}
.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 12px;
}
input:focus {
  outline: none;
}
.dataResult {
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}
a {
  text-decoration: none;
}

a:hover {
  background-color: lightgrey;
}

#clearBtn {
  cursor: pointer;
}
.grid-container {
  display: grid;
  grid-template-columns: 30% 70%;
}
.rightside {
  display: grid;
  grid-template-columns: auto auto auto;
  padding-left: 15px;
  align-items: stretch;
  align-content: space-evenly;
}
.upcard {
  height: fit-content;
  margin:5px;
}
.mediacards {
  max-height: 60vh;
  overflow-y: scroll;
  width: 440px;
}

@media (max-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 100%;
  }
  .search input {
    width: 170px;
  }
  .rightside {
    grid-template-columns: auto;
    flex-direction: column;
    justify-content: center;
    height: auto;
    align-items: center;
  }
}
@media (max-width: 1049px) and (min-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 100%;
  }
  .app {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  .rightside {
    grid-template-columns: auto auto;
    justify-content: space-around;
  }
  .calendar-container {
    width: auto !important;
  }
}
@media (max-width: 1450px) and (min-width: 1050px) {
  .rightside {
    grid-template-columns: auto auto;
    justify-content: space-around;
  }
  .mediacards {
    max-height: 60vh;
    overflow-y: scroll;
    width: 355px !important ;
  }
}
