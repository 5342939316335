.site-header {
  border: 1px solid #000000;
  height: 86px;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background: #010920;
}

.navbar {
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
  background: #010920;
}

.logo {
  text-align: center;
}

.time-header {
  display: inline;
  margin-top: 21px;
}

.mini_logo {
  display: none;
  width: 38px;
  height: 35px;
  margin-top: 21px;
}

@media screen and (max-width: 300px) {
  .navbar {
    max-width: 300px;
  }
}

@media screen and (min-width: 992px) {
  .mini_logo {
    display: none;
  }

  .time-header{
    margin-left: 121px;
  }
}
