.option-button{
  width: 75%;
  max-width: 450px;
  margin:15px;
  background: #232732;
  padding: 5px;
  color: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  border-radius: 12px;
  text-align: left;
  border: 0px;
}

.option-button.active{
  color: #00D1FF;
  border: 2px solid #00D1FF;
}

.option-label{
  width: 30.58px;
  height: 30.58px;
  margin-left: 15px;
  background: #010920;
  color:#ffffff;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  padding:8px;
  margin-right:10px;
  
}
.option-label.active{
  background: #00D1FF;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
}

/* For Future reference */
/*@media screen and (max-width: 475px){
  .previous,.next{
    /*top:585px;
    display: inline-block;
    left:20px;
    margin:25px;
    margin-top: 0;
  }
}*/
