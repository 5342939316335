.subMenu{
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    position: sticky;
    background: #464F67;
    display: flex;
    justify-content:space-evenly;
  }

  .long-text{
    display: none;
  }

  .institute{
    display: none;
  }
  
  .total{
    height: 26px;
    margin-top:15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    color: #FFFFFF;
  }
  
  .common{
    height: 26px;
    margin-top:20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }

  .time{
    height: 28px;
    margin-top: 22px;
    font-family: 'HP Simplified';
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
  }

  .finish{
    display: inline;
    margin-top: 10px;
    width: 142px;
    border: 0px;
    padding: 10px;
    background: #FC0D0D;
    border-radius: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
  }

  @media screen and (max-width: 300px){
    .subMenu{
      width:300px;
    }
  }

 @media screen and (min-width: 992px){
  .long-text{
    display: inline;
    font-weight: 400;
    font-size: 24px;
    line-height: 17px;
  }

  .short-text{
    display: none;
  }

  .subMenu{
    background: #141c32;
  }

  .finish{
    margin-top: 13px;
  }

  .total{
    margin-top: 19px;
  }

  .okNum{
    color:#00FF29;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-top: 23px;
  }

  .commonNumber{
    color: #FFFFFF;
  }

  .reviewNum{
    color:#BD00FF;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-top: 23px;
  }

  .cancelNum{
    color:#FF0000;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-top: 23px;
  }

  .institute{
    display: inline;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin-top:23px;
  }
} 