.camera {
    height: 125px;
    right:0;
    bottom: 0px;
    border: 2px solid #00d1ff;
    border-radius: 10px;
  }

 /* @media screen and (max-width: 475px){
    .camera{
      right:0;
      bottom: 0px;
      position: fixed;
      height:125px;
    }
  }*/
  