.titleclass {
    display: flex;
    justify-content: space-between;
}

.cardd{
    width: 410px !important;
    border-radius: 23px !important;
    border: 3px solid #00D1FF !important;
    margin-left: 22px !important;
    background: #010920 !important;

}
.title{
    color: white;
    font-size: 14px;
}

@media (max-width:1450px) and (min-width:1050px){
    .cardd{
        width: 320px !important;
    }
    
}
