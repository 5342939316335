.errorVideoWrapper {
  text-align: center;
  margin-top: 40px;
  color:#ffffff;
  position: relative;
}

.errorGif{
  width:65%;
  height:auto;
  margin:90px;
  z-index: -1;
}

.back{
  background: #00d1ff;
  border: 1px solid #d1d1d1;
  border-radius: 31px;
  font-size: large;
  font-weight: bold;
  color:#ffffff;
  margin: 20px;
  padding: 10px;
}


@media screen and (max-width: 300px) {
  .header {
    text-align: center;
  }

  .errorGif{
    margin: 0px;
    margin-top:60px;
    padding: 0px;
    overflow: hidden;
  }
}
